@import "styles/color.scss";
@import "styles/typography.scss";


html {
  background-image: url(./assets/img/sandpaper-pattern.jpeg);
  background-repeat: repeat;
  background-position: center;
}

body {
  background-image: url(./assets/img/sandpaper-pattern.jpeg);
  display: block;
  margin: 0;
}

ul {
  li {
    list-style: none;
  }
}

a {
  transition: .5s;
  text-decoration: none;

  &.active {

    .activeLinkDecorator {
      height: 0.125rem;
      display: flex;
      width: 100%;
      background: var(--accent-color);
      align-items: center;
      margin: 0.125rem auto;
      transition: .5s;
    }
  }
}

.contentWrapper {
  max-width: 80vw;
  margin: 4rem auto;
}
