
:root {
  --primary-color: #7f6657;
  --secondary-color: #f0ebdc;
  --text-color: #575659;
  --link-color: #2c1e21;
  --accent-color: #C6A865;
  --text-alternate-color: antiquewhite;
  --background-dark-color: #382b22;
  --background-darker-color: #130a04;
}
