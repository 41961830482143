@import "../../styles/color";

.wrapper {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: var(--background-darker-color);
  justify-content: center;
  align-items: center;
  min-height: 10.8rem;
  flex-wrap: wrap;

  ul {
    display: flex;
    width: 100%;
    margin: 1rem;
    flex-direction: row;
    gap: 1rem;
    min-height: 1.6rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-inline-start: 0;

    li{
      a{
        color: #897d70;
      }
    }
  }
}
.banner {
  position: relative;
  height: 45vh;
  width: 100%;
  display: flex;
  background-image: url("../../../src/assets/img/hero.compressed.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.flyInText{
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  position: absolute;
  width: 70vw;
  left: 40vw;
  top: 20%;
  font-weight: bold;
  color: white;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);

  &.hidden li{
    opacity: 0;
    &:nth-child(1){ transform: translateX(-200px) translateY(-200px);}
    &:nth-child(2){ transform: translateX(20px) translateY(100px);}
    &:nth-child(3){ transform: translateX(-150px) translateY(-80px);}
    &:nth-child(4){ transform: translateX(10px) translateY(-200px);}
    &:nth-child(5){ transform: translateX(-300px) translateY(200px);}
    &:nth-child(6){ transform: translateX(20px) translateY(-20px);}
    &:nth-child(7){ transform: translateX(30px) translateY(200px);}
    &:nth-child(8){ transform: translateX(-200px) translateY(-200px);}
    &:nth-child(9){ transform: translateX(20px) translateY(100px);}
    &:nth-child(10){ transform: translateX(-150px) translateY(-80px);}
    &:nth-child(11){ transform: translateX(10px) translateY(-200px);}
    &:nth-child(12){ transform: translateX(-300px) translateY(200px);}
    &:nth-child(13){ transform: translateX(20px) translateY(-20px);}
    &:nth-child(14){ transform: translateX(30px) translateY(200px);}
    &:nth-child(15){ transform: translateX(20px) translateY(-20px);}
    &:nth-child(16){ transform: translateX(30px) translateY(200px);}
    &:nth-child(17){ transform: translateX(-200px) translateY(-200px);}
    &:nth-child(18){ transform: translateX(20px) translateY(100px);}
    &:nth-child(19){ transform: translateX(-150px) translateY(-80px);}
    &:nth-child(20){ transform: translateX(-200px) translateY(-200px);}
    &:nth-child(21){ transform: translateX(20px) translateY(100px);}
  }

  li{
    display: inline-block;
    margin-right: .9rem;
    font-weight: 300;
    font-size: 200%;
    opacity: 1;
    transition: all 2.5s ease;

    &:last-child{
      margin-right:0;
    }
  }
}