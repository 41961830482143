.wrapper {
  position: relative;
  width: 100%;
  transition: .5s;

  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;

    img {
      max-width: 250px;
      margin: 0;
    }
  }
}