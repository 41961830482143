@font-face {
  font-family: Arvo;
  src: local(Arvo), url("../assets/fonts/Arvo-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Arvo;
  src: local(Arvo), url("../assets/fonts/Arvo-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Arvo;
  src: local(Arvo), url("../assets/fonts/Arvo-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Libre Baskerville;
  src: local(Arvo), url("../assets/fonts/LibreBaskerville-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: Libre Baskerville;
  src: local(Arvo), url("../assets/fonts/LibreBaskerville-Bold.ttf") format("truetype");
  font-weight: Bold;
}

@font-face {
  font-family: Libre Baskerville;
  src: local(Arvo), url("../assets/fonts/LibreBaskerville-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

html{
  font-size: 100%;
}

h1, h2, h3, h4, h5, h6, ul, li{
  font-family: Arvo, Arial, Tahoma, sans-serif;
  margin-block-start: 0;
  margin-block-end: 0;
  text-align: center;
}
p {
  font-family: Libre Baskerville, Arial, sans-serif;
  text-align: center;
  vertical-align: baseline;
  font-size: 1.125rem;
  line-height: 2rem;
  font-weight: 400;
  letter-spacing: 0;
  color:#575659;
}

a {
  color:#575659;
}

h1 {
  font-size: 1.75rem;
  line-height: 2.125rem;
  font-weight: 700;
  letter-spacing: 0.188rem;
}
h2 {
  font-size: 1.75rem;
  line-height: 2.125rem;
  font-weight: 700;
  letter-spacing: 0.188rem;
}
h3 {
  font-size: 1.75rem;
  line-height: 2.125rem;
  font-weight: 700;
  letter-spacing: 0.188rem;
}
h4 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  letter-spacing: 0.188rem;
}

h5 {
  font-size: 1.125rem;
  line-height: 2rem;
  font-weight: 400;
  letter-spacing: 0;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: 700;
}

.boldLetter {
  color: #c4ab68;
  padding: 0px 7px;
  font-size: 230%;
  font-weight: 700;
}

.lh-5 {
  line-height: 5rem;
}