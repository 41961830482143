.wrapper {
  position: relative;
  display: flex;
  width: 100%;

  .boxWrapper {
    width: 100%;
    display: flex;
    margin-top: 2rem;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: .5rem;

    .box {
      text-align: center;
      width: 30%;

      h3{
        font-size: 1.4rem;
      }

      ul {
        padding-inline-start: 0;

        li {
          font-size: 1.2rem;
          margin-top: 1.2rem;
        }
      }


    }
  }
}