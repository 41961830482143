.wrapper {
  position: relative;
  display: flex;
  width: 100%;

  ul {

    li {

      a {
        color: var(--link-color);
        font-weight: bold;
        text-decoration: underline;
        font-size: 1.125rem;
        line-height: 1.75rem;
        letter-spacing: .1275rem;
      }
    }
  }
}