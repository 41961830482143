.wrapper {
  position: relative;
  display: flex;
  width: 100%;
  background-color: var(--background-darker-color);
  justify-content: space-around;
  flex-wrap: wrap;
  .content{
    margin: 1rem auto;
    h4 {
      color: var(--text-alternate-color);
    }
    ul {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 1rem;
      padding-inline-start: 0;

      li{
        margin: 0;
        list-style: none;
        margin-block-start: 0;
        a{
          color: var(--text-alternate-color);
        }
      }
    }

    &.spacer {

    }
  }
}

